<template>
  <div>
    <section class="ShareBtn">
      <div class="cursor-pointer">
        <i class="ri-share-forward-line"></i>
        {{ $t("Events.313@share") }}
      </div>
      <iq-card style="z-index: 99999" class="p-3 shareOption">
        <div class="d-flex justify-content-around">
          <div class="optionBox" @click="handleClickPostPopup">
            <div style="background: #f5fbff">
              <i style="color: #409eff" class="las la-external-link-alt"></i>
            </div>
            {{ $t("Events.204@post") }}
          </div>
          <div class="optionBox" @click="handleClickFriendsPopup">
            <div style="background: #fffcf3">
              <i style="color: #ffb863" class="las la-user-friends"></i>
            </div>
            {{ $t("Events.4e5@friends") }}
          </div>
          <div class="optionBox" @click="handleClickInterestClubPopup">
            <div style="background: #fff5f4">
              <i class="las la-users"></i>
            </div>
            {{ $t("Events.9f9@interest_club") }}
          </div>
          <div class="optionBox" v-clipboard:copy="computedCopyLink" v-clipboard:success="copyPostLink">
            <div style="background: #f8f8f8">
              <i style="color: #999" class="las la-link"></i>
            </div>
            {{ $t("Events.fa6@copy_link") }}
          </div>
        </div>
      </iq-card>
    </section>

    <!-- created Post Modal -->
    <div v-if="showNewPostModal">
      <b-modal id="newPost" centered title="Create Post" hide-footer @hidden="hideModalFunc">
        <div class="d-flex content_post">
          <div class="user-img">
            <img style="object-fit: cover" class="avatar-60 rounded-circle" :src="avatar || defaultAvatar" />
          </div>

          <section style="flex:1">
            <section class="post_remark_content">
              <div class="detail_content" id="detail_content">
                <p v-html="remarkContent" id="p-content"></p>
              </div>
              <div class="showLave">{{ addPostObj.content.length }} / 1000</div>
              <div class="postTextarea">
                <textarea maxlength="1000" v-model="addPostObj.content" id="textarea_content"
                  :placeholder="$t('Posts.460@write_something_here')" style="
                  width: calc(100% - 15px);
                  height: 100%;
                  border: none;
                  resize: none;
                "></textarea>
                <!-- <div class="showLave">{{ addPostObj.content.length }} / 1000</div> -->
              </div>
            </section>

            <!-- location -->
            <div class="mb-3 d-flex" v-if="addPostObj.location">
              <section class="location_box">
                <i class="ri-map-pin-line"></i>
                <span>{{ addPostObj.location }}</span>
                <i class="el-icon-close" @click="addPostObj.location=''"></i>
              </section>
            </div>

            <!-- friend -->
            <ul class="modal_friend_list p-0">
              <li v-for="(item, index) in friendsInfoList" class="friend_box" :key="index">
                <span class="text-primary">@{{ item.nick_name }}</span>
                <i class="el-icon-close" @click="delFriend(index)"></i>
              </li>
            </ul>

            <!-- 拖拽上传图片 -->
            <div class="drag_container" v-loading="loadingImg3">
              <el-upload class="upload-demo" drag action="no" :http-request="params => {imgUpload(params, 3)}"
                :show-file-list="false" multiple>
                <div class="all_image d-flex flex-wrap" v-if="addPostObj.media.length">
                  <div class="common_item" :class="imgClass" v-for="(v, i) in addPostObj.media" :key="i">
                    <img v-if="v.type == 'photo'" :src="v.url" alt="" class="main_img" />
                    <video v-else :src="v.url" controls autoplay></video>
                    <img class="close_img" src="@/assets/images/add_post_close.png" @click.stop="unshiftMedia(i)" />
                  </div>
                </div>
                <div class="drag_content d-flex flex-column align-items-center justify-content-center" v-else>
                  <img src="@/assets/images/icon/drag_icon.png" alt="">
                  <div class="el-upload__text mt-3">Add Pictures / Video Drag & Drop</div>
                </div>
              </el-upload>
            </div>

          </section>
        </div>
        <div style="padding: 0 30px;">
          <hr />
        </div>

        <!-- share -->
        <div v-if="shareLink" class="share_link mb-2" style="margin:0 30px">
          <div style="width: 80px" class="img_box" v-if="
              sharePostInfomation.media && sharePostInfomation.media.length > 0
            ">
            <img style="object-fit: cover" v-if="sharePostInfomation.media[0].type == 'photo'"
              :src="sharePostInfomation.media[0].url" alt="" />
            <video v-else :src="sharePostInfomation.media[0].url"></video>
          </div>
          <div class="share_content">
            <span>@{{ sharePostInfomation.nick_name }}</span>
            <p class="m-0" v-html="postContentFilter(sharePostInfomation.content)"></p>
          </div>
        </div>

        <div class="share_link mb-3" style="margin:0 30px">
          <div class="img_box">
            <!-- <img :src="sharePostInfomation.coverImage" alt="" /> -->
            <el-image :src="sharePostInfomation.coverImage">
              <div slot="error" class="image-slot">
                <img src="@/assets/images/public/kong_img.png" style="width: 55px;height:45px;margin: 20px auto;" />
              </div>
            </el-image>
          </div>
          <div class="w-100 d-flex flex-column justify-content-between share_content">
            <span style="color: #000">{{ sharePostInfomation.title }}</span>
            <div class="w-100 d-flex justify-content-between align-items-center">
              <p class="m-0">{{ sharePostInfomation.dateTime }}</p>
              <p class="m-0 d-flex align-items-center" v-if="sharePostInfomation.country">
                <i class="ri-map-pin-line mr-2" style="font-size: 20px"></i>
                {{ sharePostInfomation.country }}
              </p>
            </div>
          </div>
        </div>

        <!-- tag -->
        <section class="d-flex align-items-center list-inline m-0 justify-content-between option_container">
          <div class="d-flex align-items-center">
            <div>
              <div class="iq-bg-primary rounded p-2 pointer mr-2">
                <el-upload class="avatar-uploader" action="no" :http-request="params => {imgUpload(params, 1)}"
                  :show-file-list="false" v-loading="loadingImg1" multiple>
                  <img src="@/assets/images/small/07.png" class="img-fluid" />
                  {{ $t("Posts.83d@photo_video") }}
                </el-upload>
              </div>
            </div>
            <div class="mr-2">
              <b-dropdown style="width: 100%">
                <template #button-content>
                  <div class="iq-bg-primary rounded text-left p-2 pointer">
                    <img src="@/assets/images/small/08.png" class="img-fluid" />
                    {{ $t("Posts.80b@tag_friend") }}
                  </div>
                </template>
                <div class="pl-2 pr-2">
                  <b-input :placeholder="$t('Posts.7ec@search_or_select_fri')" v-model="searchFriend"></b-input>
                </div>
                <b-dropdown-item @click="chooseOneFriend(item)" v-for="(item, index) in Newitems" :key="index">
                  {{ item.nick_name }}</b-dropdown-item>
              </b-dropdown>
            </div>
            <div>
              <b-dropdown style="width: 100%">
                <template #button-content>
                  <div @click="googleNearbyFunc" class="iq-bg-primary rounded text-left p-2 pointer mr-3">
                    <img src="@/assets/images/small/10.png" class="img-fluid" />
                    {{ $t("Posts.5f6@check_in") }}
                  </div>
                </template>

                <div v-if="addressLoading == 'error'" @click="googleNearbyFunc"
                  class="d-flex justify-content-center align-content-xl-start">
                  <i class="ri-refresh-line"></i>{{ $t("Posts.3f9@reload") }}
                </div>
                <div class="m-3 p-3" v-if="addressLoading == 'loading'">
                  <div v-loading="true"></div>
                </div>

                <div v-infinite-scroll="googleNearbyFunc" infinite-scroll-disabled="addressDis"
                  infinite-scroll-delay="500" v-if="addressLoading == 'success'">
                  <b-dropdown-item @click="chooseAddress(item)" v-for="(item, index) in AddressList" :key="index">
                    {{ item.name }}</b-dropdown-item>
                </div>
              </b-dropdown>
            </div>
          </div>

          <!-- permission -->
          <div class="other-option" v-if="noShowPermission">
            <el-dropdown @command="handlePermissionCommand" trigger="click">
              <span class="el-dropdown-link">
                {{ permission | permissionFilter}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="1">{{ $t("Posts.c0c@public") }}</el-dropdown-item>
                <el-dropdown-item :command="0">{{$t("Posts.9df@friends")}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </section>
        <button @click="addNewPost" class="btn btn-primary d-block w-100 add_new_post">
          {{ $t("Posts.a63@post") }}
        </button>
      </b-modal>
    </div>


    <!-- 分享到兴趣小组 -->
    <b-modal id="interestGroup" centered title="Share to Club" hide-footer>
      <ul class="p-0">
        <li class="d-flex justify-content-between mb-3" v-for="v in groupList" :key="v.id">
          <div class="d-flex">
            <div class="groupImage">
              <img :src="v.headimg" alt="" />
            </div>
            <div class="ml-3 groupName">{{ v.group_name }}</div>
          </div>
          <div>
            <b-form-radio class="cursor-pointer" v-model="stateActive" :value="v.id"></b-form-radio>
          </div>
        </li>
      </ul>

      <div class="d-flex justify-content-end">
        <b-button variant="primary" class="btnOrange mr-2" @click="(stateActive = {}), $bvModal.hide('interestGroup')">
          Cancel</b-button>
        <b-button variant="primary" @click="sendInterestGroup">Confirm</b-button>
      </div>
    </b-modal>

    <!-- 分享到群和好友 -->
    <b-modal id="friendOrGroupPopup" centered title="Share Friend Or Group" hide-footer>
      <div class="row h-100 m-0">
        <div class="col-5 contentLeft border-right">
          <!-- 搜索框 -->
          <!-- <div class="mb-3 d-flex flex-grow-1 searchBox">
            <input
              type="text"
              class="form-control"
              placeholder="Search Group"
            />
            <img src="@/assets/images/course/search.png" alt="" />
          </div> -->
          <!-- 好友列表 -->
          <ul class="p-0">
            <li class="d-flex justify-content-between mb-3" v-for="(item, index) in friendAndGroup" :key="index">
              <div class="d-flex align-items-center">
                <div class="imgBox">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="name" v-if="item.user_id">{{ item.nick_name }}</div>
                <div class="name" v-else>{{ item.group_name }}</div>
              </div>
              <div class="d-flex align-items-center">
                <input type="checkbox" class="form-check-input mr-3 mb-1" v-model="item.select" />
              </div>
            </li>
          </ul>
        </div>
        <div class="col-7 d-flex flex-column justify-content-between contentRight">
          <div>
            <div class="title">Please check the contacts you need to add.</div>
            <!-- 选中的添加好友 -->
            <ul class="p-0">
              <li class="mb-3 d-flex justify-content-between" v-for="(item, i) in filterSelectFriend" :key="i">
                <div class="d-flex align-items-center">
                  <div class="imgBox">
                    <img :src="item.avatar" alt="" />
                  </div>
                  <div class="name" v-if="item.user_id">
                    {{ item.nick_name }}
                  </div>
                  <div class="name" v-else>{{ item.group_name }}</div>
                </div>
                <div class="d-flex align-items-center cursor-pointer" @click="removeSelect(item)">
                  <img src="@/assets/images/public/close-s.png" alt="" />
                </div>
              </li>
            </ul>
          </div>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" class="btnOrange mr-2"
              @click="$bvModal.hide('friendOrGroupPopup'), (selectedList = [])">Cancel</b-button>
            <b-button variant="primary" @click="sendMessages">Confirm</b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex'
  import defaultAvatar from '@/assets/images/public/user.png'
  import { InfiniteScroll } from 'element-ui'

  export default {
    name: 'ShareBtn',
    components: {},
    props: {
      shareData: {
        type: Object
      },
      shareType: {
        type: String
      }
    },
    directives: {
      'infinite-scroll': InfiniteScroll
    },
    data() {
      return {
        defaultAvatar,
        currentPostType: '',
        groupList: [], // 俱乐部列表
        stateActive: '',
        avatar: '',
        addressLoading: false,
        // 发帖项
        addPostObj: {
          content: '',
          media: [],
          friends: [],
          location: '',
          longitude: '',
          latitude: '',
          share_type: null,
          share_id: null,
          type: 1
        },

        // 页面上遍历@friends
        friendsInfoList: [],

        // 分享
        shareLink: false,

        friendsList: [],

        // 页面上地址列表
        AddressList: [],

        noShowPermission: true,
        // 朋友可见||全部可见
        permission: 1,

        // 分享
        sharePostInfomation: {},
        friendAndGroup: [],
        // 分享好友 or 群  = > 选中
        selectedList: [],
        searchFriend: '',
        loadingImg1: '',
        loadingImg3: '',
        showNewPostModal: false
      }
    },
    filters: {
      permissionFilter(val) {
        return val == 1 ? 'Public' : 'Friends'
      }
    },
    computed: {
      // 获取当前用户相关信息
      ...mapGetters({
        dataChat: 'ChatRoom/getDataChat'
      }),
      getFriendsList() {
        return JSON.parse(JSON.stringify(this.dataChat.friendsList))
      },
      getGroupList() {
        return JSON.parse(JSON.stringify(this.dataChat.groupList))
      },
      Newitems() {
        let friendList = []
        this.friendsList.map((item) => {
          if (!item.nick_name) return
          if (
            item.nick_name.toUpperCase().includes(this.searchFriend.toUpperCase())
          ) {
            friendList.push(item)
          }
        })
        return friendList
      },
      // 过滤出选中的好友
      filterSelectFriend() {
        let friendsList = []
        this.friendAndGroup.forEach((v, i) => {
          if (v.select) {
            friendsList.push(v)
          }
        })
        this.selectedList = friendsList
        return friendsList
      },

      computedCopyLink() {
        return window.location.href
        // return `http://jci-web.summer.kim/#/newsfeeds/${this.post.info_url}`
      },

      // 判断样式
      imgClass() {
        if (this.addPostObj.media.length == 1) return 'one_img'
        if (this.addPostObj.media.length == 4) return 'four_img'
        return 'image_item'
      },
      remarkContent() {
        let val = this.addPostObj.content;
        let reg = /(^|\s+)#([^!@#$%^&*()=+./,:'"?<>(^|\s+)]+)/g;
        val = val.replace(reg, `<a>$1#$2</a>`);
        val = val.replace(/(\r\n|\n|\r)/gm, "<br />").replace(/\s{2}/g, "&nbsp;&nbsp;");
        return val;
      },
    },
    methods: {
      // 文本域滚动条事件
      scrollEvent() {
        setTimeout(() => {
          document.getElementById("textarea_content").addEventListener("scroll", handleScroll);
        }, 0);
        function handleScroll() {
          //获取dom滚动距离
          let textareaTop = document.getElementById("textarea_content");
          let contentTop = document.getElementById('detail_content');
          let p_content = document.getElementById('p-content');
          p_content.style.height = textareaTop.scrollHeight + 'px'
          contentTop.scrollTop = textareaTop.scrollTop;
        }
      },
      // 删除好友
      delFriend(index) {
        this.friendsInfoList.splice(index, 1);
        this.addPostObj.friends.splice(index, 1);
      },

      // 页面上帖子权限得选择
      handlePermissionCommand(val) {
        this.permission = val;
      },

      async beforeAvatarUpload(file) {
        const isJPG = file.type.split('/')[0] === 'image'
        const isLt5M = file.size / 1024 / 1024 < 5
        if (isJPG && !isLt5M) {
          await this._reader(file)
          return this.isLt5MYS
        } else {
          return isLt5M
        }
      },

      // 复制link
      copyPostLink(e) {
        this.$message.success('Copy successful')
      },
      // 分享到好友
      handleClickFriendsPopup() {
        let { friendsList, groupList } = this.dataChat
        let list = JSON.parse(JSON.stringify([...friendsList, ...groupList]))
        list.forEach((v, i) => {
          this.$set(v, 'select', false)
        })
        this.friendAndGroup = list
        this.$bvModal.show('friendOrGroupPopup')
      },
      // 移除选中
      removeSelect(item) {
        this.$set(item, 'select', false)
      },
      // 轮询发送消息
      async sendMessages() {
        let list = this.selectedList
        for (let index = 0; index < list.length; index++) {
          if (list[index].user_id) {
            await this.shareFriend(list[index])
          } else if (list[index].group_id) {
            await this.sendGroup(list[index])
          }
        }
        this.$bvModal.hide('friendOrGroupPopup')
      },
      // 分享内容处理
      sharefilterParams(item) {
        let params = {}
        params.share_type = this.shareType
        params.share_msg = this.shareData
        return JSON.stringify(params)
      },
      // 分享给好友
      shareFriend(item) {
        let content = this.sharefilterParams(item)
        const data = {
          push_user_id: item.user_id,
          msg_type: '10',
          content,
          client_id: this.dataChat.client_id
        }
        this.$http.sendChat(data).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: 'Share success！',
              type: 'success'
            })
            this.$bvModal.hide('friendPopup')
          }
        })
      },
      // 分享到群
      sendGroup(item) {
        let content = this.sharefilterParams(item)
        const data = {
          type: 'sendGroup',
          client_id: this.dataChat.client_id,
          msg_type: '10',
          group_id: item.group_id,
          content
        }
        this.$http.sendGroup(data).then((res) => {
          this.$message({
            message: 'Share success！',
            type: 'success'
          })
          this.$bvModal.hide('friendPopup')
        })
      },

      /**
       * 筛选分享类型及当前分享对象id
       */
      filterParams() {
        /**
         *  4 => 活动
         *  3 => 课程
         *  2 => 会议
         *  5 => 项目
         *  7 => 奖项
         */
        let arrType = ['4', '2', '3', '5', '7', '8']
        if (arrType.includes(this.shareType) && this.currentPostType === 'Post') {
          this.addPostObj.type = '1'
          this.addPostObj.share_type = this.shareType
          delete this.addPostObj.type_id
          this.addPostObj.share_id = this.shareData.id
        } else if (
          arrType.includes(this.shareType) &&
          this.currentPostType === 'InterestClub'
        ) {
          this.addPostObj.type = '2'
          this.addPostObj.share_type = this.shareType
          this.addPostObj.type_id = this.stateActive
          this.addPostObj.share_id = this.shareData.id
        }
      },
      // 类型同上 ↑↑↑
      shareDataSelect() {
        if (this.shareType === '4') {
          // console.log(4, this.shareData)
          this.sharePostInfomation.coverImage = this.shareData.img
          this.sharePostInfomation.title = this.shareData.title
          this.sharePostInfomation.dateTime = this.shareData.time
          this.sharePostInfomation.country = this.shareData.time_zone
        } else if (this.shareType === '2') {
          this.sharePostInfomation.coverImage = this.shareData.img
          this.sharePostInfomation.title = this.shareData.title
          this.sharePostInfomation.dateTime = this.shareData.time
          this.sharePostInfomation.country = this.shareData.time_zone
        } else if (this.shareType === '5') {
          // console.log('shareData555', this.shareData)
          this.sharePostInfomation.coverImage = this.shareData.img
          this.sharePostInfomation.title = this.shareData.title
          this.sharePostInfomation.dateTime = this.shareData.time
          this.sharePostInfomation.country = this.shareData.time_zone
        } else if (this.shareType === '7') {
          this.sharePostInfomation.coverImage = this.shareData.avatar
          this.sharePostInfomation.title = this.shareData.title
          this.sharePostInfomation.dateTime = this.shareData.time
          this.sharePostInfomation.country = this.shareData.time_zone
        } else if (this.shareType === '3') {
          this.sharePostInfomation.coverImage = this.shareData.img
          this.sharePostInfomation.title = this.shareData.title
          this.sharePostInfomation.dateTime = this.shareData.time
          this.sharePostInfomation.country = this.shareData.time_zone
        } else if (this.shareType === '8') {
          this.sharePostInfomation.coverImage = this.shareData.product_image
          this.sharePostInfomation.title = this.shareData.product_name
          this.sharePostInfomation.dateTime = `${this.shareData.points} Points`
          this.sharePostInfomation.country = ""
        }
      },
      // 获取俱乐部列表
      getIJoinGroupList() {
        this.$http.getIJoinGroupList({ type: 'group' }).then((res) => {
          this.groupList = res.data.data
        })
      },
      handleClickInterestClubPopup() {
        this.currentPostType = 'InterestClub'
        this.$bvModal.show('interestGroup')
        this.getIJoinGroupList()
      },
      sendInterestGroup() {
        this.$bvModal.hide('interestGroup')
        this.shareDataSelect()
        this.getUserFriendList()
        this.showNewPostModal = true
        this.$nextTick(() => {
          this.$bvModal.show('newPost')
          this.scrollEvent()
        })
      },

      handleClickPostPopup() {
        this.showNewPostModal = true
        this.currentPostType = 'Post'
        this.shareDataSelect()
        this.getUserFriendList()
        this.$nextTick(() => {
          this.$bvModal.show('newPost')
          this.scrollEvent()
        })
      },

      hideModalFunc(bvModalEvt, modalId) {
        this.showNewPostModal = false
      },

      // 获取个人信息
      getUserInfo() {
        this.$http.getUserInfo().then((res) => {
          if (res.status == 200) {
            this.userInfoList = res.data
            this.avatar = res.data.user_info.avatar
          }
        })
      },

      chooseAddress(address) {
        // console.log('address', address)
        this.addPostObj.location = address.name
        this.addPostObj.longitude = address.location.longitude
        this.addPostObj.latitude = address.location.latitude
      },

      // friendsInfoList页面上的@friends
      chooseOneFriend(user) {
        if (this.addPostObj.friends.includes(user.user_id)) return
        this.addPostObj.friends.push(user.user_id)
        this.friendsInfoList.push(user)
      },
      // 上传
      imgUpload(params, type) {
        if (this.addPostObj.media.length >= 9) return this.$message.warning('No more than 9')
        if (type == 1) {
          this.loadingImg1 = true;
        } else {
          this.loadingImg3 = true
        }
        var formData = new FormData()
        formData.append('file', params.file)
        this.$http.uploadImage(formData).then((res) => {
          this.loadingImg1 = false;
          this.loadingImg3 = false
          if (res.status == 200 && this.addPostObj.media.length < 9) {
            this.addPostObj.media.push({
              type: params.file.type.split('/')[0] == 'image' ? 'photo' : 'video',
              url: res.data[0]
            })
          }
        })
      },
      // permision--好友列表
      getUserFriendList() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        this.$http
          .getUserFriendList({
            user_id: userInfo.id,
            type: 4
          })
          .then((res) => {
            this.friendsList = res.data
          })
      },
      // 删除已上传的图片
      unshiftMedia(index) {
        this.addPostObj.media.splice(index, 1)
      },

      // addressList
      googleNearbyFunc() {
        this.addressLoading = 'loading'
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.$http
                .googleNearby({
                  longitude: position.coords.longitude,
                  latitude: position.coords.latitude,
                  pagetoken: this.nextAddressToken ? this.nextAddressToken : ''
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.AddressList = [...this.AddressList, ...res.data.name]
                    if (!res.data.next_page_token) {
                      this.addressDis = true
                    }
                    this.nextAddressToken = res.data.next_page_token
                    this.addressLoading = 'success'
                  } else {
                    this.addressLoading = 'error'
                  }
                })
            },
            (error) => {
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  this.$message.warning(
                    'You reject the request for geographic location'
                  )
                  break
                case error.POSITION_UNAVAILABLE:
                  this.$message.warning('Location information is not available')
                  break
                case error.TIMEOUT:
                  this.$message.warning('Request for your location timed out')
                  break
                case error.UNKNOWN_ERROR:
                  this.$message.warning('unknown mistake')
                  break
              }
            }
          )
        } else {
          this.$message.warning(
            'Your browser does not support the use of HTML5 to obtain geographic location services!'
          )
        }
      },
      // 新增帖子
      addNewPost() {
        this.filterParams()
        this.addPost(this.addPostObj, this.permission)
      },
      addPost(post, permission) {
        // console.log("post", post);
        this.$http
          .createPost({
            permission,
            ...post
          })
          .then((res) => {
            if (res.status == 200) {
              this.$bvModal.hide('newPost')
            }
          })
      },
      // qingkong表单
      clearForm() {
        this.addPostObj.content = ''
        this.addPostObj.friends = []
        this.addPostObj.media = []
        this.addPostObj.share_type = null
        this.friendsInfoList = []
        // this.AddressList = [];
        this.addPostObj.location = ''
        this.searchFriend = ''

        this.shareLink = false
        this.permission = 1
      }
    },
    mounted() {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        // this.friendsList.forEach((v, i) => {
        //   v.select = false;
        // });
        this.clearForm()
      })
    },
    created() {
      // 获取address
      // this.googleNearbyFunc();
      this.getUserInfo()
      // console.log("data:", this.shareData);
      // console.log("type:", this.shareType);
    }
  }
</script>
<style src='' lang='scss' scoped>
  @media screen and (min-width: 992px) {
    ::v-deep #friendOrGroupPopup {
      .modal-dialog {
        max-width: 620px !important;
      }
    }
  }
  @media (min-width: 600px){
    .shareOption {
      width: 420px;
    }
  }
  @media (max-width: 600px){
    .shareOption {
      width: 380px;
    }
  }

  ::v-deep #newPost {

    .modal-content,
    .modal-header {
      width: 660px !important;
      border-bottom: 0;
    }

    .modal-body {
      padding: 0;
      box-sizing: border-box;
    }

    .content_post {
      padding: 15px 30px 0;
      border-top: 1px solid #f1f1f1;
    }

    hr {
      border-color: #f1f1f1;
    }

    .option_container {
      padding: 0 30px;
    }

    .el-dropdown {
      color: #50B5FF;
      cursor: pointer;
    }

    .add_new_post {
      border-radius: 0;
      height: 50px;
      font-size: 18px;
      margin-top: 20px;
    }

    .drag_container {
      width: 100%;
      padding-left: 20px;

      .drag_content {
        height: 160px;
        background: #f6f6f6;
      }

      .el-upload-dragger {
        width: 500px;
        border: 0;
        height: auto;
        overflow: visible;
      }

      .el-upload__text {
        color: #ccc;
        font-size: 14px;
        line-height: 16px;
        width: 146px;
        margin: 0 auto;
      }

      .el-loading-mask {
        background-color: rgba(255, 255, 255, .5);
      }

      .all_image {
        width: 100%;

        .four_img:nth-child(2) {
          margin-right: 30px !important;
        }

        .image_item:nth-child(3n) {
          margin-right: 0 !important;
        }

        .main_img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }

      .common_item {
        position: relative;
        margin-bottom: 10px;

        .close_img {
          width: 20px;
          height: 20px;
          position: absolute;
          right: -5px;
          top: -5px;
        }
      }

      .image_item {
        width: 160px;
        height: 160px;
        margin-right: 10px;
      }

      .four_img {
        width: 160px;
        height: 160px;
        margin-right: 10px;
      }

      .one_img {
        text-align: left;

        .main_img,
        video {
          width: auto;
          height: auto;
          max-width: 500px !important;
          max-height: 500px;
        }
      }
    }

    .location_box {
      display: flex;
      align-items: center;
      border: 1px solid #FFBA68;
      color: #FFBA68;
      padding: 0 6px;
      border-radius: 15px;
      margin-left: 20px;
      width: auto;
      line-height: 26px;

      i {
        margin-right: 3px;
        font-size: 18px;
      }

      .el-icon-close {
        font-size: 16px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .friend_box {
      display: flex;
      align-items: center;
      border: 1px solid #50B5FF;
      color: #50B5FF;
      padding: 0 6px;
      border-radius: 15px;
      width: auto;
      line-height: 24px;
      margin-right: 5px;
      margin-bottom: 5px;

      .el-icon-close {
        font-size: 16px;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .modal_friend_list {
      display: flex;
      margin-left: 20px;
      margin-bottom: 10px;
      flex-wrap: wrap;
    }

  }

  // 地址弹框
  ::v-deep .dropdown-menu {
    width: 350px;
    height: 300px;
    overflow: auto;
  }

  ::v-deep #interestGroup {
    .modal-content {
      max-height: 700px;
      overflow-y: scroll;
    }

    ul {
      li {
        height: 60px;
        background: #f8f8f8;
        border-radius: 5px;
        align-items: center;
        padding: 0 15px;

        .groupImage {
          width: 30px;
          height: 30px;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }

    .cancelBtn {
      width: 78px;
      height: 35px;
      background: #fff5f4;
      border-radius: 5px;
      color: #ff9b8a;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  ::v-deep #friendOrGroupPopup {
    .modal-content {
      min-height: 520px !important;

      .modal-body {
        padding: 0;

        .contentLeft {
          padding: 1rem;
          padding-left: 22px;
          height: 460px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 0 !important;
          }
        }

        .name {
          width: 60%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .contentRight {
          padding: 1rem;
          padding-right: 30px;
          height: 460px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            width: 0 !important;
          }

          .title {
            font-size: 14px;
            color: #666666;
            margin-bottom: 30px;
            text-align: center;
          }
        }
      }
    }

    .imgBox {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .cancelBtn {
      width: 78px;
      height: 35px;
      background: #fff5f4;
      border-radius: 5px;
      color: #ff9b8a;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ShareBtn {
    display: flex;
    position: relative;
    color: #50b5ff;

    i {
      font-size: 20px;
    }

    .shareOption {
      height: 105px;
      position: absolute;
      top: 34px;
      left: -18px;
      display: none;
      z-index: 99999;

      .optionBox {
        text-align: center;
        color: #3f414d;
        cursor: pointer;

        div {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          margin-bottom: 5px;
        }

        i {
          font-size: 26px;
          color: #ff9b8a;
        }
      }
    }

    &:hover .shareOption {
      display: block;
    }
  }

  .user-img99 {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  // @friends
  .modal_friend_list {
    display: flex;
  }

  ::v-deep .el-upload {
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  ::v-deep .dropdown-toggle::after {
    display: none;
  }

  ::v-deep .btn-secondary {
    padding: 0;
    background-color: #fff !important;
    border-color: #fff !important;
  }

  .media_box {
    display: flex;
    flex-wrap: wrap;

    >.li_pic {
      width: 69px;
      height: 69px;
      margin-right: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
      overflow: hidden;
      border-radius: 8px;
      position: relative;

      >img,
      >video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; //图片自适应的样式
      }

      .close_img {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 2px;
        top: 2px;
        cursor: pointer;
      }
    }
  }

  .share_link {
    border: 1px solid #f1f1f1;
    background-color: #f8f8f8;
    display: flex;

    >.img_box {
      width: 90px;
      height: 80px;
      overflow: hidden;

      .el-image {
        width: 100%;
        height: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .share_content {
      padding: 2px 5px;

      >p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }

  ::v-deep .textareaPost {
    height: 60px;

    .el-textarea__inner {
      height: 60px;
      border: none;
      resize: none;
    }

    .el-input__count {
      bottom: -20px;
      background: transparent;
    }
  }

  .postTextarea {
    textarea {
      box-sizing: border-box;
      color: transparent;
      background-color: transparent;
      caret-color: #000;
      padding: 0;

      &:focus {
        outline: none;
      }
    }

    .showLave {
      position: absolute;
      bottom: -19px;
      right: 0;
      font-size: 12px;
    }
  }

  .post_remark_content {
    width: 100%;
    position: relative;
    margin-left: 20px;

    .detail_content {
      width: calc(100% - 15px);
      overflow: auto;
      height: 100px;
      margin-bottom: 20px;
      color: #333;
      word-break: break-word;

      /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #fff;
      }
    }

    .showLave {
      position: absolute;
      bottom: -19px;
      right: 18px;
      font-size: 12px;
    }
  }

  .postTextarea {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100px;
    border: none;
    resize: none;
    background-color: transparent;
    word-break: break-word;
  }
</style>